export const forget = async (email, dominio) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_URL_APIHN24}/forget`, {
        method: "POST",
        headers: {
          'Authorization': 'Basic ' + btoa('hinetService:99RHinet202'),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email,
          dominio,
        }),
      });
  
      // Manejo de errores en la respuesta HTTP
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Error en la solicitud");
      }
  
      // Parseo de la respuesta
      const data = await response.json();
      return data; // Retornamos los datos para que puedan ser utilizados por quien llame a la función
  
    } catch (error) {
      console.error("Error en la solicitud forget:", error.message);
      throw error; // Relanzamos el error para que pueda ser manejado externamente
    }
  };
  
  export const reset = async (key,dominio,password1,password2) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_URL_APIHN24}/reset`, {
        method: "POST",
        headers: {
          'Authorization': 'Basic ' + btoa('hinetService:99RHinet202'),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          key,
          dominio,
          password1,
          password2
        }),
      });
  
      // Manejo de errores en la respuesta HTTP
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Error en la solicitud");
      }
  
      // Parseo de la respuesta
      const data = await response.json();
      return data; // Retornamos los datos para que puedan ser utilizados por quien llame a la función
  
    } catch (error) {
      console.error("Error en la solicitud forget:", error.message);
      throw error; // Relanzamos el error para que pueda ser manejado externamente
    }
  };