import React, { useContext, useEffect, useState } from "react";
import { UserConfigContext } from "../../../context/UserConfigContext";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import SwiperCore, { Autoplay } from "swiper";

// Activa el módulo Autoplay en Swiper
SwiperCore.use([Autoplay]);

const ProductNovedades = () => {
  const [brands, setBrands] = useState([]);
  const config = useContext(UserConfigContext);

  useEffect(() => {
    if (config && config.marcas_imagenes) {
      setBrands(config.marcas_imagenes);
    }
  }, [config]);

  return (
    <div className="w-full mx-auto max-w-screen-lg">
    <Swiper
      autoplay={{
        delay: 1500,
        disableOnInteraction: false,
      }}
      loop={true}
      
      breakpoints={{
        640: {
          slidesPerView: 2, // En pantallas pequeñas
        },
        768: {
          slidesPerView: 3, // En pantallas medianas
        },
        1024: {
          slidesPerView: 5, // En pantallas grandes
        },
      }}
    >
      {brands.map((brand) => (
        <SwiperSlide key={brand.clave_id} className="w-full px-2">
          <div className="w-full flex ">
            <img
              className="w-full object-cover"
              src={brand.imagen}
              style={{
                height: "125px", // Altura fija
              }}
              alt="brand"
            />
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  </div>
  
  );
};

export default ProductNovedades;
