import React, { useEffect, useState, useRef } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { MdClose } from "react-icons/md";
import { HiMenuAlt2 } from "react-icons/hi";
import { motion } from "framer-motion";
import { FaSearch, FaUser, FaCaretDown, FaShoppingCart } from "react-icons/fa";
import { loadUserConfig } from "../../../constants/config";
import { useSelector } from "react-redux";

const Header = () => {
  const products = useSelector((state) => state.orebiReducer.products);
  const [showUser, setShowUser] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [user, setUser] = useState(null);
  const [empresaNombre, setEmpresaNombre] = useState("");
  const [tipo, setTipo] = useState("");
  const [empresaLogo, setEmpresaLogo] = useState("");
  const [VistaMoneda, setVistaMoneda] = useState("");
  const [VistaSimbolo, setVistaSimbolo] = useState("");
  const [Simbolo, setSimbolo] = useState("");

  const location = useLocation();
  const navigate = useNavigate();
  const refUserMenu = useRef();

  // Fetch user and configuration data
  useEffect(() => {
    const fetchUserAndConfig = async () => {
      const loggedUserData = JSON.parse(localStorage.getItem("user"));
     console.log(loggedUserData );
      if (loggedUserData?.data?.user) {
        setUser(loggedUserData.data.user);
      }
      if (loggedUserData?.data?.empresas?.[0]?.tipo) {
        setTipo(loggedUserData.data.empresas[0].tipo);
      }

      const config = await loadUserConfig();
      if (config?.empresa?.fantasia) {
        setEmpresaNombre(config.empresa.fantasia);
        document.title = config.empresa.fantasia;
      }
      if (config?.vista?.monedaEcommerce) {
        setVistaMoneda(config.vista.monedaEcommerce.monedaCotizacion || "");
        setVistaSimbolo(config.vista.monedaEcommerce.monedaDescripcion || "");
        setSimbolo(config.vista.monedaEcommerce.monedaSimbolo || "");
      }
      if (config?.empresa?.logo) {
        setEmpresaLogo(config.empresa.logo);
      }
    };

    fetchUserAndConfig();
  }, []);

  // Close menus when navigating to a new page
useEffect(() => {
  setShowUser(false); // Close user menu
  setShowMenu(false); // Close any other menus
}, [location.pathname]); // Trigger this effect when the path changes


  const handleExternalLink = (urlKey) => {
    const loggedUserData = JSON.parse(localStorage.getItem("user"));
    const url = loggedUserData?.data?.[urlKey];
    setShowUser(false); // Cerrar el menú del usuario
    setShowMenu(false); // Cerrar otros menús si aplica
    if (url) {
      window.open(url, "_blank");
    } else {
      alert(`${urlKey} no disponible.`);
    }
  };

  const logoutUser = () => {
    localStorage.removeItem("user");
    setUser(null);
    navigate("/signin");
  };

  return (
    <div className="w-full h-20 bg-white sticky top-0 z-50 border-b-[1px] border-b-gray-200">
      <nav className="h-full px-4 max-w-container mx-auto relative">
        <div className="flex items-center justify-between h-full">
          {/* Logo */}
          <Link to="/">
            <h1 className="text-2xl font-bold cursor-pointer flex items-center">
              {empresaLogo && (
                <img
                  src={empresaLogo}
                  alt={`${empresaNombre} logo`}
                  className="w-25 h-10 mr-4"
                />
              )}
              {empresaNombre}
            </h1>
          </Link>

          {VistaSimbolo && VistaMoneda && (
            <NavLink
              to="/"
              className="font-normal text-base text-[#008C8C]"
            >
              {VistaMoneda !== 1
                ? `Moneda: ${VistaSimbolo}(${Simbolo}). Cotización: $${VistaMoneda}`
                : "Moneda: Dolar(U$s). Cotización: $1000"}
            </NavLink>
          )}

          {/* Desktop Menu */}
          <div className="hidden md:flex items-center gap-6">
            <motion.ul
              initial={{ y: 30, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.5 }}
              className="flex items-center gap-4"
            >
              <NavLink
                to="/"
                className="font-normal text-base text-[#767676] hover:font-bold hover:text-[#262626]"
              >
                Productos
              </NavLink>
              <NavLink
                to="/Offer"
                className="font-normal text-base text-[#767676] hover:font-bold hover:text-[#262626]"
              >
                Ofertas
              </NavLink>
              <NavLink
                to="/News"
                className="font-normal text-base text-[#767676] hover:font-bold hover:text-[#262626]"
              >
                Novedades
              </NavLink>
            </motion.ul>

            {/* User Menu */}
            <div className="relative" ref={refUserMenu}>
              {user ? (
                <div
                  onClick={() => setShowUser(!showUser)}
                  className="flex items-center gap-2 cursor-pointer"
                >
                  <FaUser />
                  <span>{user.nombre}</span>
                  <FaCaretDown />
                </div>
              ) : (
                <div
                  className="flex items-center gap-2 cursor-pointer"
                  onClick={() => navigate("/signin")}
                >
                  <FaUser />
                  <span>Iniciar Sesión</span>
                </div>
              )}
              {showUser && (
                <motion.div
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-50"
                >
                  <ul className="p-2">
                    {tipo === "u" ? (
                      <>
                        <li
                          onClick={() => {
                              handleExternalLink("url_pedidos"); // Abre el enlace
                            }}
                          className="cursor-pointer hover:bg-gray-100 p-2 rounded-md"
                        >
                          Mis pedidos
                        </li>
                        <li
                          onClick={() => navigate("/Favorite")}
                          className="cursor-pointer hover:bg-gray-100 p-2 rounded-md"
                        >
                          Mis Favoritos
                        </li>
                        <li
                          onClick={() => navigate("/Notices")}
                          className="cursor-pointer hover:bg-gray-100 p-2 rounded-md"
                        >
                           Avisos de Ingresos
                        </li>
                      </>
                    ) : (
                      <>
                        <li
                          onClick={() => handleExternalLink("url_admin")}
                          className="cursor-pointer hover:bg-gray-100 p-2 rounded-md"
                        >
                          Admin Ecommerce
                        </li>
                        <li
                          onClick={() => handleExternalLink("url_pedidos")}
                          className="cursor-pointer hover:bg-gray-100 p-2 rounded-md"
                        >
                          Pedidos
                        </li>
                      </>
                    )}
                    
                    <li
                      onClick={() => navigate("/Receive")}
                      className="cursor-pointer hover:bg-gray-100 p-2 rounded-md"
                    >
                      Próximos Ingresos
                    </li>
                    <li
                      onClick={logoutUser}
                      className="cursor-pointer hover:bg-gray-100 p-2 rounded-md"
                    >
                      Cerrar Sesión
                    </li>
                  </ul>
                </motion.div>
              )}
            </div>

            {/* Cart */}
            <Link to="/cart">
              <button
                className={`flex items-center justify-center p-2 rounded-full ${
                  products.length > 0 ? "bg-green-700" : "bg-gray-400"
                }`}
               
              >
                <FaShoppingCart className="text-lg text-white" />
                <span className="ml-2 text-sm font-semibold text-white">
                  Carrito
                </span>
                {products.length > 0 && (
                  <span className="ml-1 text-xs font-semibold text-white">
                    ({products.length})
                  </span>
                )}
              </button>
            </Link>
          </div>

          {/* Mobile Menu Toggle */}
          <div className="md:hidden flex items-center">
            <HiMenuAlt2
              className="text-2xl cursor-pointer"
              onClick={() => setShowMenu(!showMenu)}
            />
          </div>
        </div>

        {/* Mobile Menu */}
        {showMenu && (
          <motion.div
            initial={{ x: "100%" }}
            animate={{ x: 0 }}
            exit={{ x: "100%" }}
            className="fixed top-0 right-0 h-full w-3/4 bg-white z-50 shadow-md p-4"
          >
            <MdClose
              className="text-2xl cursor-pointer"
              onClick={() => setShowMenu(false)}
            />
            <ul className="mt-6 flex flex-col gap-4">
              <NavLink
                to="/"
                className="font-normal text-base text-[#767676] hover:font-bold hover:text-[#262626]"
              >
                Productos
              </NavLink>
              <NavLink
                to="/Offer"
                className="font-normal text-base text-[#767676] hover:font-bold hover:text-[#262626]"
              >
                Ofertas
              </NavLink>
              <NavLink
                to="/News"
                className="font-normal text-base text-[#767676] hover:font-bold hover:text-[#262626]"
              >
                Novedades
              </NavLink>
            </ul>
          </motion.div>
        )}
      </nav>
    </div>
  );
};

export default Header;
