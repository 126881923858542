import React from "react";
import { useNavigate } from "react-router-dom"; // Asegúrate de importar navigate
import { BsSuitHeartFill } from "react-icons/bs";
import { GiReturnArrow } from "react-icons/gi";
import { FaShoppingCart } from "react-icons/fa";
import { MdOutlineLabelImportant } from "react-icons/md";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import SwiperCore, { Autoplay } from "swiper";
import { formatPrice } from "../../../constants/funciones";
import { useDispatch } from "react-redux";
import { addToCart } from "../../../redux/orebiSlice";

// Activa el módulo Autoplay en Swiper
SwiperCore.use([Autoplay]);

const ProductsOnSale = ({ relatedProducts }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();


  const idString = (name) => {
    return String(name)
      .toLowerCase()
      .replace(/\s+/g, "") // Reemplaza espacios por nada
      .replace(/[^\w-]/g, ""); // Elimina caracteres no válidos (incluido '/')
  };
  

  const handleProductDetails = (product) => {
    const rootId = idString(product.Descripcion); // Asegúrate de definir idString o importarla
    navigate(`/product/${rootId}`, {
      state: { item: product }, // Envía el objeto de producto completo
    });
  };

  return (
    <div>
      <h3 className="font-titleFont text-2xl font-semibold mb-6 decoration-[1px]">
        Productos Relacionados
      </h3>
      <div className="w-full mx-auto">
        <Swiper
          spaceBetween={30}
          autoplay={{
            delay: 1500,
            disableOnInteraction: false,
          }}
          loop={true}
          breakpoints={{
            640: { slidesPerView: 2 },
            768: { slidesPerView: 3 },
            1024: { slidesPerView: 4 },
          }}
        >
          {relatedProducts.map((product) => (
            <SwiperSlide key={product.codigo} className="p-4">
              <div className="w-full relative group border rounded-lg overflow-hidden shadow-md hover:shadow-lg transition-shadow duration-300">
                <div className="w-full relative overflow-hidden">
                <a onClick={() => handleProductDetails(product)}>
                    <img
                      className="w-full h-auto object-cover cursor-pointer"
                      src={product.img}
                    />
                  </a>
                  <div className="absolute bottom-0 w-full bg-white transition-transform duration-700 transform translate-y-full group-hover:translate-y-0">
                    <ul className="flex flex-col gap-2 font-titleFont px-2">
                      <li className="text-[#767676] hover:text-primeColor text-sm border-b border-b-gray-200 flex items-center justify-between pb-1 cursor-pointer duration-300">
                        Compartir
                        <GiReturnArrow />
                      </li>
                      <li onClick={() =>
                dispatch(
                  addToCart({
                    _id: product.codigo,
                    name: product.Descripcion,
                    quantity: 1,
                    image: product.img,
                    price: product.PrecioOferta != null && product.PrecioOferta > 0 
                    ? product.PrecioOferta
                    : product.PrecioLista,                  
                    colors: product.color,
                  })
                )
              }
              className="text-[#767676] hover:text-primeColor text-sm border-b border-b-gray-200 flex items-center justify-between pb-1 cursor-pointer duration-300">
                        Agregar al Carrito
                        <FaShoppingCart />
                      </li>
                      <li
                        onClick={() => handleProductDetails(product)}
                        className="text-[#767676] hover:text-primeColor text-sm border-b border-b-gray-200 flex items-center justify-between pb-1 cursor-pointer duration-300"
                      >
                        Ver Detalles
                        <MdOutlineLabelImportant className="text-lg" />
                      </li>
                      <li className="text-[#767676] hover:text-primeColor text-sm border-b border-b-gray-200 flex items-center justify-between pb-1 cursor-pointer duration-300">
                        Agregar a Favoritos
                        <BsSuitHeartFill />
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="max-w-80 py-4 flex flex-col gap-1 border-t border-gray-200 px-4">
                  <p className="text-[#767676] text-[14px]">
                    Código: {product.codigo}
                  </p>
                  <h2 className="text-lg text-primeColor font-bold">
                    {product.Descripcion}
                  </h2>
                  <p className="text-[#767676] text-[14px]">
                    {product.PrecioOferta > 0 ? (
                      <>
                        <span style={{ textDecoration: "line-through" }}>
                        {product.simbolo}{formatPrice(product.PrecioLista)}
                        </span>{" "}
                        &nbsp;
                        <span> {product.simbolo}{formatPrice(product.PrecioOferta)}</span>
                      </>
                     ) : (
                      <span>
                        {product.simbolo} {formatPrice(product.PrecioLista)}
                      </span>
                    )}
                  </p>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default ProductsOnSale;
