import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  createRoutesFromElements,
  Route,
  ScrollRestoration,
} from "react-router-dom";
import Footer from "./components/home/Footer/Footer";
import FooterBottom from "./components/home/Footer/FooterBottom";
import Header from "./components/home/Header/Header";
import HeaderBottom from "./components/home/Header/HeaderBottom";
import SpecialCase from "./components/SpecialCase/SpecialCase";
import About from "./pages/About/About";
import SignIn from "./pages/Account/SignIn";
import SignUp from "./pages/Account/SignUp";
import Cart from "./pages/Cart/Cart";
import Notices from "./pages/Notices/Notices";
import Home from "./pages/Home/Home";
import Receive from "./pages/Receive/Receive";
import Offer from "./pages/Offer/Offer";
import Payment from "./pages/payment/Payment";
import ProductDetails from "./pages/ProductDetails/ProductDetails";
import Profile from "./pages/Profile/Profile";
import ProtectedRoute from "./components/ProtectedRoute"; 
import News from "./pages/News/News";
import { UserConfigProvider } from "./context/UserConfigContext"; 
import Forget from "./pages/Forget/Forget";
import Favorite from "./pages/Favorite/Favorite";
import Recover from "./pages/Recover/Recover";


const Layout = () => {
  return (
    <div>
      <Header />
    {/*<HeaderBottom /><SpecialCase />*/}
      <ScrollRestoration />
      <Outlet />
      <Footer />
      <FooterBottom />
    </div>
  );
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/" element={<Layout />}>
        {/* ==================== Header Navlink Start here =================== */}
        <Route index element={<ProtectedRoute element={<Home />} />} />
       
        <Route path="/about" element={<ProtectedRoute element={<About />} />} />
        <Route path="/Notices" element={<ProtectedRoute element={<Notices />} />} />
        <Route path="/Receive" element={<ProtectedRoute element={<Receive />} />} />
        {/* ==================== Header Navlink End here ===================== */}
        <Route path="/offer" element={<ProtectedRoute element={<Offer />} />} />
        <Route path="/product/:_id" element={<ProtectedRoute element={<ProductDetails />} />} />
        <Route path="/cart" element={<ProtectedRoute element={<Cart />} />} />
        <Route path="/paymentgateway" element={<ProtectedRoute element={<Payment />} />} />
        <Route path="/profile" element={<ProtectedRoute element={<Profile />} />} />
        <Route path="/News" element={<News />} />
        <Route path="/Favorite" element={<Favorite />} />
      </Route>
      <Route path="/signup" element={<SignUp />} />
      <Route path="/signin" element={<SignIn />} />
      <Route path="/Forget" element={<Forget />} />
      <Route path="/recuperaclave" element={<Recover />} />
    </Route>
  )
);

function App() {
  return (
    // Aquí envolvemos la aplicación con el UserConfigProvider para que todos los componentes puedan acceder a la configuración
    <UserConfigProvider>
      <div className="font-bodyFont">
        <RouterProvider router={router} />
      </div>
    </UserConfigProvider>
  );
}

export default App;
