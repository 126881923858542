import React, { useState,useEffect } from "react";
import { BsSuitHeartFill } from "react-icons/bs";
import { GiReturnArrow } from "react-icons/gi";
import { FaShoppingCart } from "react-icons/fa";
import { MdOutlineLabelImportant } from "react-icons/md";
import Image from "../../designLayouts/Image";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addToCart } from "../../../redux/orebiSlice";
import { formatPrice } from "../../../constants/funciones";
import { loadAddfavorites, loadFavorites,paginationItems7 } from "../../../constants/products";
import Swal from "sweetalert2";

const Product = (props) => {
 
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isFavorite, setIsFavorite] = useState(false);

  const idString = (name) => {
    return String(name)
      .toLowerCase()
      .replace(/\s+/g, "") // Reemplaza espacios por nada
      .replace(/[^\w-]/g, ""); // Elimina caracteres no válidos (incluido '/')
  };

  const rootId = idString(props.Descripcion);

  const handleProductDetails = () => {
    navigate(`/product/${rootId}`, {
      state: { item: props }, // Envía el objeto de producto completo
    });
  };


  const toggleFavorite = async () => {
    const loggedUserData = JSON.parse(localStorage.getItem("user"));
    if (!loggedUserData || !loggedUserData.data || !loggedUserData.data.user) {
      console.log("Usuario no autenticado, redirigiendo a SignIn.");
      navigate("/signin");
      return;
    }

    const userId = loggedUserData.data.user.id;
    const isFavoriteValue = isFavorite ? 1 : 0;

    try {
      console.log(userId, props.codigo, isFavoriteValue);
      await loadAddfavorites(userId, props.codigo, isFavoriteValue);

      if (isFavoriteValue === 1) {
        setIsFavorite(false); // Cambiar a gris
        Swal.fire("Eliminado de favoritos", "", "success");
         // Si estás en la página "Favorites", recarga la página
         if (window.location.pathname === "/Favorite") {
          window.location.reload();
        }
      } else {
        setIsFavorite(true); // Cambiar a verde
        Swal.fire("Añadido a favoritos", "", "success");
      }
    } catch (error) {
      console.error("Error al actualizar favoritos:", error);
      Swal.fire("Error", "No se pudo actualizar favoritos.", "error");
    }
  };

  useEffect(() => {
    const checkFavorites = async () => {
      const loggedUserData = localStorage.getItem("user");
      if (!loggedUserData) return;
  
      const userData = JSON.parse(loggedUserData);
      if (!userData?.data?.user?.id) return;
  
      try {
        await loadFavorites(userData.data.user.id);
        const isFavorited = paginationItems7.some((fav) => fav.codigo === props.codigo);
        setIsFavorite(isFavorited);
      } catch (error) {
        console.error("Error al cargar favoritos:", error);
      }
    };
  
    if (props?.codigo) {
      checkFavorites();
    }
  }, [props?.codigo]);
  


  return (
    <div className="w-full relative group border rounded-lg overflow-hidden shadow-md hover:shadow-lg transition-shadow duration-300">
      <div className="w-full relative overflow-hidden">
        {/* Imagen responsiva */}
        <Image className="w-full h-auto object-cover" imgSrc={props.img} />
      
        <div className="absolute bottom-0 w-full bg-white transition-transform duration-700 transform translate-y-full group-hover:translate-y-0">
          <ul className="flex flex-col gap-2 font-titleFont px-2">
            <li className="text-[#767676] hover:text-primeColor text-sm border-b border-b-gray-200 flex items-center justify-between pb-1 cursor-pointer duration-300">
              Compartir
              <GiReturnArrow />
            </li>
            <li
              onClick={() =>
                dispatch(
                  addToCart({
                    ...props,
                    quantity: 1,
                  })
                )
              }
              className="text-[#767676] hover:text-primeColor text-sm border-b border-b-gray-200 flex items-center justify-between pb-1 cursor-pointer duration-300"
            >
              Agregar al Carrito
              <FaShoppingCart />
            </li>
            <li
              onClick={handleProductDetails} // Maneja el clic para ver detalles
              className="text-[#767676] hover:text-primeColor text-sm border-b border-b-gray-200 flex items-center justify-between pb-1 cursor-pointer duration-300"
            >
              Ver Detalles
              <MdOutlineLabelImportant className="text-lg" />
            </li>
            <li
              onClick={toggleFavorite} // Llama a la función para agregar o eliminar favoritos
              className={`text-sm border-b border-b-gray-200 flex items-center justify-between pb-1 cursor-pointer duration-300 ${
                isFavorite ? "text-green-500" : "text-[#767676]"
              }`}
            >
              {isFavorite ? "Eliminar de Favoritos" : "Agregar a Favoritos"}
              <BsSuitHeartFill />
            </li>
          </ul>
        </div>
      </div>
      <div className="max-w-80 py-4 flex flex-col gap-1 border-t border-gray-200 px-4">
        <p className="text-[#767676] text-[14px]">Código: {props.codigo}</p>
        <h2 className="text-lg text-primeColor font-bold">
          {props.Descripcion}
        </h2>
        <p className="text-[#767676] text-[14px]">
          {props.PrecioOferta > 0 ? (
            <>
              <span style={{ textDecoration: "line-through" }}>
                ${formatPrice(props.PrecioLista)}
              </span>
              &nbsp;
              <span>${formatPrice(props.PrecioOferta)}</span>
            </>
          ) : (
            `$${formatPrice(props.PrecioLista)}`
          )}
        </p>
      </div>
    </div>
  );
};

export default Product;
