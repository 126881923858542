import React, { useState, useEffect, useCallback } from "react";
import ReactPaginate from "react-paginate";
import Product from "../../home/Products/Product";
import { paginationItems7, loadFavorites, paginationMeta6 } from "../../../constants/products";
import { loadUserConfig } from "../../../constants/config";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addToCart } from "../../../redux/orebiSlice";
import ProductTable2 from "./ProductTable2";
import debounce from "lodash.debounce"; // Asegúrate de instalar lodash.debounce

const Items = ({ products, viewType, hoveredItem, setHoveredItem }) => {
  const [quantities, setQuantities] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleQuantityChange = (codigo, value) => {
    setQuantities((prev) => ({ ...prev, [codigo]: Number(value) }));
  };

  const handleAddToCart = (item, quantityToAdd) => {
    dispatch(addToCart({ ...item, quantity: Number(quantityToAdd) }));
  };

  const handleImageClick = (item) => {
    const cod = item.codigo.replace(/\//g, "|");
    navigate(`/product/${cod}`, { state: { item } });
  };


  return viewType === "table" ? (
    <ProductTable2
      products={products}
      hoveredItem={hoveredItem}
      setHoveredItem={setHoveredItem}
      handleImageClick={handleImageClick}
      handleQuantityChange={handleQuantityChange}
      handleAddToCart={handleAddToCart}
      quantities={quantities}
    />
  ) : (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-10 mdl:gap-4 lg:gap-10">
      {products.map((item) => (
        <div key={item.codigo} className="w-full">
          <Product
            img={item.img}
            codigo={item.codigo}
            Descripcion={item.Descripcion}
            PrecioLista={item.PrecioLista}
            PrecioOferta={item.PrecioOferta}
            favorito={item.favorito}
          />
        </div>
      ))}
    </div>
  );
};

const PaginationFav = () => {
  const [products, setProducts] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [viewType, setViewType] = useState("table");
  const [hoveredItem, setHoveredItem] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [selectedGroup, setSelectedGroup] = useState("0");
  const [selectedBrand, setSelectedBrand] = useState("0");
  const [selectedType, setSelectedType] = useState("0");
  const [config, setConfig] = useState({ grupos: [], marcas: [], atributos: [] });
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const loggedUserData = JSON.parse(localStorage.getItem("user")) || {};
  const user = loggedUserData?.data?.user?.id;

  const getLoadFunction = () => {
    const path = location.pathname;
    return loadFavorites;
  };

  const fetchProducts = async () => {
    setLoading(true);
    try {
      const loadFunction = getLoadFunction();
      await loadFunction(user,pageNumber, itemsPerPage, searchTerm, selectedGroup, selectedType, selectedBrand);
      const data = paginationItems7;
      setProducts(data);
    } catch (error) {
      console.error("Error loading products:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchConfig = async () => {
    try {
      const userConfig = await loadUserConfig();
      setConfig(userConfig);
    } catch (error) {
      console.error("Error loading config:", error);
    }
  };

  const debouncedFetchProducts = useCallback(debounce(fetchProducts, 500), [pageNumber, itemsPerPage, searchTerm, selectedGroup, selectedType, selectedBrand]);

  useEffect(() => {
    fetchConfig();
    debouncedFetchProducts();
    return () => {
      debouncedFetchProducts.cancel();
    };
  }, [pageNumber, itemsPerPage, searchTerm, selectedGroup, selectedBrand, selectedType, debouncedFetchProducts]);

  const handleSearchTermChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSelectChange = (setter) => (e) => {
    setter(e.target.value);
    setPageNumber(1); // Reset to first page
  };

  const pageCount = paginationMeta6.totalPages;

  return (
    <div className="p-5">
      {/* Inputs y Selectores */}
      <div className="flex mb-4">
        <input
          type="text"
          placeholder="Buscar productos..."
          value={searchTerm}
          onChange={handleSearchTermChange}
          className="flex-grow border border-gray-300 rounded p-2"
        />
       <select value={itemsPerPage} onChange={(e) => setItemsPerPage(Number(e.target.value))} className="ml-2 border border-gray-300 rounded p-2">
          <option value={50}>50</option>
          <option value={75}>75</option>
          <option value={100}>100</option>
          <option value={-1}>Todos</option>
        </select>
        <select value={viewType} onChange={(e) => setViewType(e.target.value)} className="ml-2 border border-gray-300 rounded p-2">
          <option value="grid">Grid</option>
          <option value="table">Tabla</option>
        </select>
      </div>
      <div className="flex mb-4">
      {config.filtros?.condicion === 1 && (
    <select
      value={selectedType}
      onChange={handleSelectChange(setSelectedType)}
      className="mr-2 border border-gray-300 rounded p-2 w-full"
    >
      <option value="0">Seleccionar Condición</option>
      {config.atributos.map((tipo, index) => (
        <option key={index} value={tipo.codigo}>
          {tipo.descripcion}
        </option>
      ))}
    </select>
  )}
    {config.filtros?.grupos === 1 && (
    <select
      value={selectedGroup}
      onChange={handleSelectChange(setSelectedGroup)}
      className="mr-2 border border-gray-300 rounded p-2 w-full"
    >
      <option value="0">Seleccionar Grupo</option>
      {config.grupos.map((grupo, index) => (
        <option key={index} value={grupo.codigo}>
          {grupo.descripcion}
        </option>
      ))}
    </select>
  )}
 {config.filtros?.marcas === 1 && (
    <select
      value={selectedBrand}
      onChange={handleSelectChange(setSelectedBrand)}
      className="mr-2 border border-gray-300 rounded p-2 w-full"
    >
      <option value="0">Seleccionar Marca</option>
      {config.marcas.map((marca, index) => (
        <option key={index} value={marca.clave_id}>
          {marca.descripcion}
        </option>
      ))}
    </select>
  )}
</div>

      {/* Cargando o Mostrando Productos */}
      {loading ? (
        <div className="text-center text-xl my-4">Cargando...</div>
      ) : (
        <Items products={products} viewType={viewType} hoveredItem={hoveredItem} setHoveredItem={setHoveredItem} />
      )}
    
     {/* Paginación */}
     {pageCount > 1 && (
        <ReactPaginate
          nextLabel="Siguiente"
          onPageChange={(e) => setPageNumber(e.selected + 1)}
          pageCount={pageCount}
          forcePage={pageNumber - 1}
           previousLabel="Anterior"
          pageClassName="page-item mx-1 rounded-full text-gray-700 border border-gray-300 hover:bg-blue-100"
          pageLinkClassName="page-link px-3 py-1"
          previousClassName="page-item mx-1 rounded-full text-gray-700 border border-gray-300 hover:bg-blue-100"
          previousLinkClassName="page-link px-3 py-1"
          nextClassName="page-item mx-1 rounded-full text-gray-700 border border-gray-300 hover:bg-blue-100"
          nextLinkClassName="page-link px-3 py-1"
          breakLabel="..."
          breakClassName="page-item mx-1"
          breakLinkClassName="page-link px-3 py-1"
          containerClassName="pagination flex justify-center items-center mt-8"
          activeClassName="bg-blue-500 text-white rounded-full"
        />
      )}
    </div>
  );
};

export default PaginationFav;
