import React, { useState } from "react";
import { Link } from "react-router-dom";
import { forget } from "../../constants/olvide";

const Forget = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false); 

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Obtener la URL de origen
    const baseUrl = window.location.origin;

    if (email) {
      setIsLoading(true);
      setMessage(""); 
      setIsError(false);

      try {
        // Llamada a la función forget
        const response = await forget(email, baseUrl);

        // Manejar la respuesta basada en `error`
        if (response.error === false) {
          setMessage(response.message); // Mostrar el mensaje devuelto
          setIsError(false); // Indicar que no hay error
        } else {
          setMessage("Hubo un error al enviar los datos. Intenta nuevamente.");
          setIsError(true); // Indicar que hubo un error
        }
      } catch (error) {
        setMessage("Ocurrió un error. Por favor, intenta más tarde.");
        setIsError(true);
      } finally {
        setIsLoading(false); // Ocultar el mensaje de carga
      }

      setEmail(""); // Limpiar el input de email

      // Hacer que el mensaje desaparezca después de 5 segundos
      setTimeout(() => {
        setMessage("");
      }, 5000);
    } else {
      setMessage("Por favor, ingresa un correo válido.");
      setIsError(true);

      // Hacer que el mensaje desaparezca después de 5 segundos
      setTimeout(() => {
        setMessage("");
      }, 5000);
    }
  };

  return (
    <div className="min-h-screen flex flex-col justify-center items-center bg-gray-50 px-4">
      <div className="w-full max-w-2xl bg-white rounded-xl shadow-lg p-8">
        <h1 className="text-3xl font-bold text-gray-800 text-center mb-4">
          ¿Olvidaste tu contraseña?
        </h1>
        <p className="text-sm text-gray-600 text-center mb-6">
          Ingresa tu email para recibir los pasos para recuperar tu contraseña.
        </p>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <input
              type="email"
              placeholder="Correo electrónico"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-400 focus:outline-none"
            />
          </div>

          <button
            type="submit"
            className="w-full p-3 bg-indigo-500 text-white font-medium rounded-lg hover:bg-indigo-600 transition"
            disabled={isLoading} // Deshabilitar botón mientras carga
          >
            {isLoading ? "Enviando correo..." : "Recuperar"} {/* Cambiar texto del botón */}
          </button>
        </form>

        {/* Mostrar mensaje */}
        {message && (
          <p
            className={`mt-4 text-center text-sm ${
              isError ? "text-red-600" : "text-green-600"
            }`}
          >
            {message}
          </p>
        )}

        <div className="text-center mt-6 text-sm">
          <Link to="/signup" className="text-indigo-500 hover:underline">
            Regístrate
          </Link>{" "}
          |{" "}
          <Link to="/signin" className="text-indigo-500 hover:underline">
            Volver a Inicio
          </Link>
        </div>
      </div>

      <footer className="mt-10 text-gray-500 text-sm text-center">
        &copy; {new Date().getFullYear()} HiNet Sistemas. Todos los derechos reservados.
      </footer>
    </div>
  );
};

export default Forget;
