import React, { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { reset } from "../../constants/olvide";
import CryptoJS from "crypto-js";

const Recover = () => {
  const [searchParams] = useSearchParams();
  const key = searchParams.get("key");
  const navigate = useNavigate();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [passwordError, setPasswordError] = useState(false); // Nuevo estado

  useEffect(() => {
    if (success) {
      const timer = setTimeout(() => {
        navigate("/signin");
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [success, navigate]);

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);

    // Validar longitud de la contraseña
    if (value.length > 0 && value.length < 8) {
      setPasswordError(true);
    } else {
      setPasswordError(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    setMessage("");

    if (!key) {
      setIsLoading(false);
      setMessage("La clave no es válida o ha expirado.");
      setIsError(true);
      return;
    }

    if (password !== confirmPassword) {
      setIsLoading(false);
      setMessage("Las contraseñas no coinciden.");
      setIsError(true);
      return;
    }

    if (password.length < 8 || confirmPassword.length < 8) {
      setIsLoading(false);
      setMessage("Ambas contraseñas deben tener al menos 8 caracteres.");
      setIsError(true);
      return;
    }

    try {
      const encryptedPassword = CryptoJS.MD5(password).toString();
      const encryptedConfirmPassword = CryptoJS.MD5(confirmPassword).toString();

      const baseUrl = window.location.origin;
      const response = await reset(key, baseUrl, encryptedPassword, encryptedConfirmPassword);

      if (response.error === false) {
        setSuccess(true);
        setMessage(response.message);
        setIsError(false);
      } else {
        setMessage(response.message || "Hubo un error al cambiar la contraseña. Intenta nuevamente.");
        setIsError(true);
      }
    } catch (err) {
      console.error(err);
      setMessage("Ocurrió un error inesperado. Intenta más tarde.");
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-md w-96">
        {success ? (
          <div className="text-center">
            <h2 className={`text-xl font-bold ${isError ? "text-red-600" : "text-green-600"}`}>
              {message}
            </h2>
            <p className="mt-4 text-sm text-gray-500">
              Serás redirigido a la página de inicio de sesión en unos segundos...
            </p>
          </div>
        ) : (
          <>
            <h2 className="text-2xl font-bold text-gray-800 text-center mb-6">
              Cambiar Contraseña
            </h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-600"
                >
                  Nueva Contraseña
                </label>
                <input
                  type="password"
                  id="password"
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  value={password}
                  onChange={handlePasswordChange} // Usar el nuevo manejador
                  required
                />
                {passwordError && (
                  <p className="text-xs text-red-500 mt-1">
                    La contraseña debe tener al menos 8 caracteres.
                  </p>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="confirmPassword"
                  className="block text-sm font-medium text-gray-600"
                >
                  Confirmar Contraseña
                </label>
                <input
                  type="password"
                  id="confirmPassword"
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
              </div>

              {message && (
                <p className={`text-sm mb-4 ${isError ? "text-red-500" : "text-green-500"}`}>
                  {message}
                </p>
              )}
              <button
                type="submit"
                className="w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600 transition"
                disabled={isLoading}
              >
                {isLoading ? "Cambiando..." : "Cambiar"}
              </button>
            </form>
            {isError && (
              <div className="text-center mt-4">
                <a
                  href="/Forget"
                  className="text-blue-500 underline hover:text-blue-700"
                >
                  Volver a intentar
                </a>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Recover;
