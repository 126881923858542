import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { login } from "../../constants/config";
import CryptoJS from "crypto-js"; // Importa la librería
import ProductOferta from "../../components/pageProps/productDetails/ProductOferta";
import ProductNovedades from "../../components/pageProps/productDetails/ProductNovedades";
import { UserConfigContext } from "../../context/UserConfigContext";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const SignIn = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errEmail, setErrEmail] = useState("");
  const [errPassword, setErrPassword] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [logo, setLogo] = useState("");
  const [empresaNombre, setEmpresaNombre] = useState("");
  const [isRecovering, setIsRecovering] = useState(false); // Nuevo estado

  const config = useContext(UserConfigContext);

  useEffect(() => {
    if (config && config.empresa) {
      setLogo(config.empresa.logo);
      setEmpresaNombre(config.empresa.nombre);
    }
  }, [config]);

  const handleEmail = (e) => {
    setEmail(e.target.value);
    setErrEmail("");
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
    setErrPassword("");
  };

  const handleSignUp = async () => {
    if (!email) {
      setErrEmail("Ingresa tu correo electrónico");
      return;
    }
    if (!password) {
      setErrPassword("Ingresa tu contraseña");
      return;
    }
    try {
       // Hash de la contraseña con MD5
       const hashedPassword = CryptoJS.MD5(password).toString();
       const response = await login(email, hashedPassword);

      if (response) {
        localStorage.setItem("user", JSON.stringify(response));
        setSuccessMsg("Inicio de sesión exitoso.");
        setErrorMsg("");
        setEmail("");
        setPassword("");
        navigate("/");
      }
    } catch (error) {
      setErrorMsg(error.message);
    }
  };



  return (
<div className="w-full bg-gray-200 min-h-screen flex justify-center items-center">
  {/* Contenedor principal centrado y comprimido */}
  <div className="mx-auto max-w-screen-lg w-full">
    {/* Primer bloque - Formulario y ProductoOfertas */}
    <div className="flex ">
      {/* Lado Izquierdo - Formulario */}
      <div className="w-4/5 p-4 ">
      <img 
  src={logo} 
  alt="Logo" 
  title={empresaNombre} 
  className="w-auto h-[220px] block mx-auto" 
/>

        <form className="flex flex-col gap-2 bg-white p-4 rounded-md mt-2">
        <h1 className="font-titleFont text-2xl font-semibold text-center">Ingrese sus datos</h1>
        <label className="font-titleFont text-base font-semibold text-gray-500" htmlFor="email">
                    Correo Electrónico
                  </label>
                  <input
                    id="email"
                    onChange={handleEmail}
                    value={email}
                    className="w-full h-9 px-4 text-base border rounded-md border-gray-400 outline-none"
                    type="email"
                    placeholder="email@dominio.com"
                  />
                   <label className="font-titleFont text-base font-semibold text-gray-500" htmlFor="email">
                   Password
                  </label>
                  <div className="relative">
                <input
                  id="password"
                  onChange={handlePassword}
                  value={password}
                  className="w-full h-9 px-4 text-base border rounded-md border-gray-400 outline-none"
                  type={showPassword ? "text" : "password"}
                  placeholder="Ingrese su Clave"
                />
                <span
                  className="absolute top-2 right-2 cursor-pointer"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </span>
              </div>
              <button
                type="button"
                onClick={handleSignUp}
                className="bg-blue-500 text-white py-2 rounded hover:bg-blue-600 transition duration-300"
              >
                Ingresar
              </button>
                <div className="text-center text-sm">
                  <Link to="/signup" className="text-gray-600 hover:text-blue-700 cursor-pointer">Regístrese</Link>{" "}
                  {" | "}
                  <Link to="/Forget" className="text-gray-600 hover:text-blue-700 cursor-pointer">¿Olvidaste tu contraseña?</Link>
                </div>
                {errorMsg && <p className="text-sm text-red-500">{errorMsg}</p>}
                {successMsg && <p className="text-sm text-green-500">{successMsg}</p>}

        </form>
      </div>

      {/* Lado Derecho - ProductoOfertas */}
      <div className="p-4 ">   
      <ProductOferta />
      </div>
    </div>

    {/* Segundo bloque - Novedades alineado */}
 
      <div className="w-full  p-2">
        <ProductNovedades />
      </div>
    

    {/* Tercer bloque - Footer */}
    <div className="w-full text-center">
      <p className="text-sm text-gray-600">
        Copyright © {new Date().getFullYear()} HiNet Sistemas Todos los derechos reservados.
      </p>
    </div>
  </div>
</div>



  );
  
  
  
  
};

export default SignIn;

