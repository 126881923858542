import React, { useState } from "react";
import PaginationFav from "../../components/pageProps/shopPage/PaginationFav";

const Favorite = () => {
  const [prevLocation] = useState("");

  return (
    <div className="max-w-container mx-auto">
      <div className="pb-10">
      <h1 className="text-4xl font-bold mt-6 mb-4">Mis Favoritos</h1>
    
      <PaginationFav />
      </div>
    </div>
  );
};

export default Favorite;
