
/**ORDEN */
export const sendOrder = async (user, products, empresa,moneda,cotizacion,notas) => {
  // Función para generar un ID aleatorio de 6 dígitos
  const generateRandomOrderId = () => Math.floor(100000 + Math.random() * 900000).toString();
  
  // Calcular el total del carrito sumando el precio de los productos
  const totalCarro = products.reduce((total, product) => {
    const precioBase = product.PrecioOferta ? product.PrecioOferta : product.PrecioLista;
    return total + (precioBase * product.quantity * cotizacion);
  }, 0).toFixed(2);

  // Obtener fecha y hora actual en formato ISO y ajustarlo a la zona horaria específica
  const currentDateTime = new Date().toISOString(); // Fecha en formato UTC

  // Crear los detalles de la orden incluyendo los productos y la info del usuario
  const orderData = {
    order: {
      order_id: generateRandomOrderId(), // Número aleatorio de 6 dígitos
      doc_type: "CUIT",
      Notas:notas,
      shipment_type: "",
      seller_id: "",
      empresa: empresa,
      deposito: "01",
      moneda: moneda,
      cotizacion: cotizacion,
      punto: "00001",
      vendedor: "",
      DNI: user.cuit,
      ApellidoyNombre: user.nombre,
      Direccion: user.direccion, // Dirección del usuario
      Ciudad: user.localidad, // Ciudad del usuario
      Provincia: user.provincia, // Provincia del usuario
      Email: user.email, // Email del usuario
      Telefono: user.telefono, // Teléfono del usuario
      FechaPago: "", // Fecha de pago actual currentDateTime
      NroMercadoPago1: "", // Número de MercadoPago
      ImportePago1: 0, // Total del carrito ajustado por cotización totalCarro
      NroMercadoPago2: "",
      ImportePago2: "",
      NroMercadoPago3: "",
      ImportePago3: "",
      NroMercadoPago4: "",
      ImportePago4: "",
      NroMercadoPago5: "",
      ImportePago5: "",
      retiraEnSucursal: "",
      TipoPago: "0"
    },
    itemDetails: products.map(product => {
      const precioBase = product.PrecioOferta ? product.PrecioOferta : product.PrecioLista;
      return {
        Sku: product.codigo,
        Cantidad: product.quantity,
        Descripcion: product.Descripcion,
        Precio: (precioBase * cotizacion).toFixed(2), // Precio ajustado por la cotización
      };
    })
  };
  try {
    // Enviar la orden a la API
    const response = await fetch(process.env.REACT_APP_URL_APIHN24 + "/AgregarPedidoMultipagosHn24", {
      method: "POST",
      headers: {
        'Authorization': 'Basic ' + btoa('hinetService:99RHinet202'),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(orderData)
    });

    // Manejar la respuesta
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Error al enviar la orden");
    }

    const result = await response.json();
    console.log("Orden enviada con éxito:", result);
    return result; // Devolver la respuesta

  } catch (error) {
    console.error("Error al enviar la orden:", error);
    throw error; // Lanzar el error para que se maneje en el catch de handleProcessOrder
  }
};