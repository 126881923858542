import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../../../redux/orebiSlice";
import { formatPrice } from "../../../constants/funciones";
import { loadAddfavorites, loadFavorites,paginationItems7 } from "../../../constants/products";
import { useNavigate } from "react-router-dom";
import { FaHeart } from "react-icons/fa";
import Swal from "sweetalert2";

const ProductInfo = ({ productInfo }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [quantity, setQuantity] = useState(0);
  const [previousQuantity, setPreviousQuantity] = useState(0);
  const [isFavorite, setIsFavorite] = useState(false);
  const loggedUserData = JSON.parse(localStorage.getItem("user"));
  const userType = loggedUserData?.data?.empresas[0]?.tipo;
  
  const handleQuantityChange = (e) => {
    const value = Math.max(0, parseInt(e.target.value, 10) || 0);
    setQuantity(value);
    setPreviousQuantity(value);
  };

  const handleButtonClick = () => {
    const newQuantity = quantity === previousQuantity ? quantity + 1 : quantity;
    setQuantity(newQuantity);
    setPreviousQuantity(newQuantity);

    dispatch(
      addToCart({
        ...productInfo,
        quantity: newQuantity,
      })
    );
  };

  const toggleFavorite = async () => {
    const loggedUserData = JSON.parse(localStorage.getItem("user"));
    if (!loggedUserData || !loggedUserData.data || !loggedUserData.data.user) {
      console.log("Usuario no autenticado, redirigiendo a SignIn.");
      navigate("/signin");
      return;
    }

    const userId = loggedUserData.data.user.id;
    const isFavoriteValue = isFavorite ? 1 : 0;

    try {
      console.log(userId, productInfo.codigo, isFavoriteValue);
      await loadAddfavorites(userId, productInfo.codigo, isFavoriteValue);

      if (isFavoriteValue === 1) {
        setIsFavorite(false); // Cambiar a gris
        Swal.fire("Eliminado de favoritos", "", "success");
      } else {
        setIsFavorite(true); // Cambiar a verde
        Swal.fire("Añadido a favoritos", "", "success");
      }
    } catch (error) {
      console.error("Error al actualizar favoritos:", error);
      Swal.fire("Error", "No se pudo actualizar favoritos.", "error");
    }
  };

  useEffect(() => {
    if (productInfo) {
      // Verificar si el producto está en favoritos
      const checkFavorites = async () => {
        const loggedUserData = JSON.parse(localStorage.getItem("user"));
        if (!loggedUserData || !loggedUserData.data || !loggedUserData.data.user) {
          return;
        }

        const userId = loggedUserData.data.user.id;

        try {
          await loadFavorites(userId);
          const isFavorited = paginationItems7.some(
            (fav) => fav.codigo === productInfo.codigo
          );
          setIsFavorite(isFavorited);
        } catch (error) {
          console.error("Error al cargar favoritos:", error);
        }
      };

      checkFavorites();
    }
  }, [productInfo]);

  if (!productInfo) {
    return <div>Cargando...</div>;
  }

  return (
    <div className="flex flex-col gap-5">
      <h2 className="text-2xl font-semibold text-gray-600">
        {productInfo.Descripcion || "Cargando..."}
      </h2>

      <p className="text-xl font-semibold">
        {productInfo.PrecioOferta > 0 ? (
          <>
            <span style={{ textDecoration: "line-through" }}>
              {productInfo.simbolo}
              {formatPrice(productInfo.PrecioLista)}
            </span>
            &nbsp;
            <span>
              {productInfo.simbolo}
              {formatPrice(productInfo.PrecioOferta)}
            </span>
          </>
        ) : (
          <span>
            {productInfo.simbolo} {formatPrice(productInfo.PrecioLista)}
          </span>
        )}
      </p>

      <p className="text-base text-gray-600">
        {productInfo.codigo || "Cargando..."}
      </p>

      <div className="flex items-center gap-3">
        <label htmlFor="quantity" className="text-lg font-semibold">
          Cantidad:
        </label>
        <input
          id="quantity"
          type="number"
          value={quantity}
          min="0"
          onChange={handleQuantityChange}
          className="w-16 p-2 border border-gray-300 rounded"
        />

        <button
          onClick={handleButtonClick}
          className="py-2 px-4 bg-primeColor hover:bg-black duration-300 text-white text-sm font-medium rounded"
        >
          Añadir producto
        </button>
        {userType === "u" && (
        <span
          onClick={toggleFavorite}
          className={`cursor-pointer flex items-center justify-center w-12 h-12 text-3xl rounded-full border-2 ${
            isFavorite ? "text-green-700 border-green-700" : "text-gray-500 border-gray-500"
          }`}
        >
          <FaHeart />
        </span>
        )}
      </div>

      {productInfo.atributo && (
        <div className="flex items-center gap-3">
          <label htmlFor="condition" className="text-lg font-semibold">
            Condición:
          </label>
          <span>{productInfo.atributo}</span>
        </div>
      )}
    </div>
  );
};

export default ProductInfo;
