
/*INFORMACION USUARIO*/
export const loadUserConfig = async () => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_URL_APIHN24}/configuracion/${process.env.REACT_APP_DB_EMPRESA}`,
      {
        method: "GET",
        headers: {
          'Authorization': 'Basic ' + btoa('hinetService:99RHinet202'), // Autenticación básica
          'Content-Type': 'application/json',
        },
      }
    );

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Error al cargar la configuración de usuario");
    }

    const data = await response.json();

    // Formatear la información necesaria
    const config = {
      empresa: {
        id: data.data?.empresa?.id || null,
        nombre: data.data?.empresa?.nombre || "Nombre no disponible",
        fantasia: data.data?.empresa?.nombrefantasia || "Nombre de fantasía no disponible",
        imagen: data.data?.empresa?.rimagen || "Sin imagen",
        cuit: data.data?.empresa?.cuit || "CUIT no disponible",
        direccion: data.data?.empresa?.direccion || "Dirección no disponible",
        provincia: data.data?.empresa?.pcia || "Provincia no disponible",
        pais: data.data?.empresa?.id_pais || "País no disponible",
        emailAdmin: data.data?.empresa?.mailadmin || "Correo no disponible",
        logo: data.data?.empresa?.rimagen || "Logo no disponible",
      },
      grupos: Array.isArray(data.data?.grupos)
        ? data.data.grupos.map(grupo => ({
            codigo: grupo.Codigo || "Código no disponible",
            descripcion: grupo.Descrip || "Descripción no disponible",
            imagen: grupo.rutaimagen || "Imagen no disponible",
          }))
        : [],
      atributos: Array.isArray(data.data?.atributos1)
        ? data.data.atributos1.map(tipo => ({
            codigo: tipo.id || "Código no disponible",
            descripcion: tipo.valor?.trim() || "Descripción no disponible",
          }))
        : [],
      marcas: Array.isArray(data.data?.marcas)
        ? data.data.marcas.map(marca => ({
            clave_id: marca.Clave_id || "Clave no disponible",
            descripcion: marca.Descripcion || "Descripción no disponible",
            imagen: marca.rutaimagen || "Imagen no disponible",
          }))
        : [],
      marcas_imagenes: Array.isArray(data.data?.marcas_imagenes)
        ? data.data.marcas_imagenes.map(marca => ({
            clave_id: marca.Clave_id || "Clave no disponible",
            descripcion: marca.Descripcion || "Descripción no disponible",
            imagen: marca.rutaimagen || "Imagen no disponible",
          }))
        : [],
      filtros: {
          condicion: data.data?.filtros?.condicion || 0,
          grupos: data.data?.filtros?.grupos || 0,
          marcas: data.data?.filtros?.marcas || 0,
        },
      colores: Array.isArray(data.data?.colores)
        ? data.data.colores.map(color => color.codigo || "Código no disponible")
        : [],
        vista: {
          login: data.data?.vista?.login || false, // Asegura que la propiedad 'login' exista y sea booleana
          vista: data.data?.vista?.vista || "tabla", // Valor por defecto 'tabla' si no está disponible
          monedaEcommerce: data.data?.vista?.monedaEcommerce || {} // Objeto vacío como valor por defecto
        }
    };

    // console.log("Configuración cargada:", config);
    return config; // Devuelve el objeto configurado para su uso en la aplicación

  } catch (error) {
    // console.error("Error al cargar la configuración del usuario:", error.message);
    throw error; // Re-lanza el error para que pueda manejarse donde se llama esta función
  }
};

export const login = async (email, password, uuid='') => {
  try {
    const response = await fetch(`${process.env.REACT_APP_URL_APIHN24}/login`, {
      method: "POST",
      headers: {
        'Authorization': 'Basic ' + btoa('hinetService:99RHinet202'),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email,
        password,
        uuid,
      }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Error en el inicio de sesión");
    }

    const data = await response.json();
  //console.log("Login exitoso:", data);
  //console.log('url',data.data.url_pedidos);
    // Suponiendo que la URL de los pedidos está en la respuesta
    const urlPedidos = data.data.url_pedidos || ''; // Ajusta según la respuesta real del backend

    // Guardamos la URL de los pedidos en el localStorage
    localStorage.setItem('url_pedidos', urlPedidos);

    return data;

  } catch (error) {
    console.error("Error en el inicio de sesión:", error.message);
    throw error;
  }
};


export const logout = async (email, password = '', uuid = "q") => {
  try {
    // Validar variable de entorno
    if (!process.env.REACT_APP_URL_APIHN24) {
      throw new Error("La variable de entorno REACT_APP_URL_APIHN24 no está configurada.");
    }

    // Validar datos requeridos
    if (!email) {
      throw new Error("El correo electrónico es obligatorio para cerrar sesión.");
    }

    // Preparar el cuerpo de la solicitud
    const body = {
      email,
      password,
      uuid,
    };

    console.log("Datos enviados al servidor:", body);

    // Realizar la solicitud al servidor
    const response = await fetch(`${process.env.REACT_APP_URL_APIHN24}/logout`, {
      method: "POST",
      headers: {
        'Authorization': 'Basic ' + btoa('hinetService:99RHinet202'), // Autenticación básica
        'Content-Type': 'application/json', // Especificar que el contenido es JSON
      },
      body: JSON.stringify(body),
    });

    // Manejo de errores HTTP
    if (!response.ok) {
      let errorMessage = "Error en el cierre de sesión";
      try {
        const errorData = await response.json();
        errorMessage = errorData.message || errorMessage;
      } catch (parseError) {
       // console.error("Error al procesar la respuesta del servidor:", parseError);
      }
      throw new Error(errorMessage);
    }

    // Opcional: Manejo del resultado de éxito
  //  console.log("Cierre de sesión exitoso.");

  } catch (error) {
    // Manejar errores
    //console.error("Error en el cierre de sesión:", error.message);
    throw error; // Relanzar el error para que quien llame a esta función lo maneje
  }
};

