import React, { useState } from "react";
import Pagination from "../../components/pageProps/shopPage/Pagination";

const News = () => {
  const [prevLocation] = useState("");

  return (
    <div className="max-w-container mx-auto">
      <div className="pb-10">
      <h1 className="text-4xl font-bold mt-6 mb-4">Novedades</h1>
    
      <Pagination />
      </div>
    </div>
  );
};

export default News;
