import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { resetCart } from "../../redux/orebiSlice";
import { emptyCart } from "../../assets/images/index";
import { formatPrice } from "../../constants/funciones";
import Swal from "sweetalert2";
import { loadUserConfig } from "../../constants/config";
import { sendOrder } from "../../constants/order";
import { emailpedido } from "../../constants/products";
import { ImBin } from "react-icons/im";
import {
  deleteItem,
  drecreaseQuantity,
  increaseQuantity,
} from "../../redux/orebiSlice";

const Cart = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const products = useSelector((state) => state.orebiReducer.products);
  const [totalAmt, setTotalAmt] = useState(0);
  const [shippingCharge, setShippingCharge] = useState(0);
  const [empresaId, setEmpresaId] = useState("");
  const [Simbolo, setSimbolo] = useState("");
  const [Cotizacion, setCotizacion] = useState("");
  const [Codigo, setCodigo] = useState("");
  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const config = await loadUserConfig();
        if (config?.empresa?.id) {
          setEmpresaId(config.empresa.id); // Guardar el ID de la empresa
        }
        if (config.vista.monedaEcommerce.monedaSimbolo) {
          setSimbolo(config.vista.monedaEcommerce.monedaSimbolo); // Guardar el logo de la empresa
        }
        if (config.vista.monedaEcommerce.monedaCodigo) {
          setCodigo(config.vista.monedaEcommerce.monedaCodigo); // Guardar el logo de la empresa
        }
        
        if (config.vista.monedaEcommerce.monedaCotizacion) {
          setCotizacion(config.vista.monedaEcommerce.monedaCotizacion); // Guardar el logo de la empresa
        }
      } catch (error) {
        console.error("Error al cargar configuración de usuario:", error);
      }
    };

    fetchConfig();
    const price = products.reduce((acc, item) => acc + ((item.PrecioOferta || item.PrecioLista) * item.quantity), 0);
    setTotalAmt(price);
  }, [products]);

  useEffect(() => {   
      setShippingCharge(0);
  }, [totalAmt]);

  const handleImageClick = (item) => {
    const cod = item.codigo.replace(/\//g, "|");
    navigate(`/product/${cod}`, { state: { item } });
  };

  const handleProcessOrder = async () => {
    const loggedUserData = JSON.parse(localStorage.getItem("user"));
    if (!loggedUserData || !loggedUserData.data || !loggedUserData.data.user) {
      console.log("Usuario no autenticado, redirigiendo a SignIn.");
      navigate("/signin");
      return;
    }

    const user = loggedUserData.data.user;

    try {
      const notas = document.getElementById("notas").value;
      const response = await sendOrder(user, products, empresaId,Codigo,Cotizacion,notas);

      if (response.error == true) {
        Swal.fire({
          icon: "error",
          title: "Hubo un error en el pedido",
          text: "Inténtalo de nuevo más tarde.",
        });
      } else {
        // Resetear carrito y mostrar mensaje de éxito
        dispatch(resetCart());
        Swal.fire({
          icon: "success",
          title: response.message || "Orden procesada exitosamente",
          text: "Volver a la Tienda...",
        }).then(async () => {
          // Enviar el correo al cliente
          try {
            await emailpedido(response.nro_interno, user.email); // orderId devuelto por la API
            console.log("Correo enviado al cliente con éxito.");
          } catch (emailError) {
            console.error("Error al enviar el correo:", emailError.message || emailError);
            Swal.fire({
              icon: "error",
              title: "Error al enviar el correo",
              text: "El pedido fue procesado, pero no se pudo enviar el correo.",
            });
          }

          navigate("/");
        });
      }
    }  catch (error) {
      console.error("Error al procesar la orden:", error.message || error);
      Swal.fire({
        icon: "error",
        title: "Hubo un error al procesar la orden",
        text: error.response?.data?.message || "Inténtalo de nuevo más tarde.",
      });
    }
  };

  return (
    <div className="max-w-7xl mx-auto px-4">
      <h1 className="text-2xl md:text-4xl font-bold mt-6 mb-4">Mi pedido</h1>
      {products.length > 0 ? (
        <div className="pb-20">
          <div className="overflow-x-auto">
            <table className="w-full border-collapse mb-4 text-sm md:text-lg">
              <thead>
                <tr className="bg-gray-100 text-primeColor">
                  <th className="text-left p-2">Producto</th>
                  <th className="text-right p-2">Precio</th>
                  <th className="text-center p-2">Cantidad</th>
                  <th className="text-right p-2">Sub Total</th>
                </tr>
              </thead>
              <tbody>
                {products.map((item) => {
                  const price = item.PrecioOferta || item.PrecioLista;
                  const total = item.quantity * price;
  
                  return (
                    <tr key={item.codigo} className="border-b">
                      <td className="flex items-center gap-4 p-4">
                        <ImBin
                          onClick={() => dispatch(deleteItem(item.codigo))}
                          className="text-primeColor hover:text-red-500 duration-300 cursor-pointer"
                        />
                        <img
                          src={item.img}
                          alt={item.Descripcion}
                          className="w-12 h-12 md:w-16 md:h-16 object-cover cursor-pointer"
                          onClick={() => handleImageClick(item)}
                        />
                        <span className="text-sm md:text-base">
                          {item.Descripcion}
                        </span>
                      </td>
                      <td className="text-right p-2">
                        {item.simbolo}
                        {formatPrice(price)}
                      </td>
                      <td className="text-center">
                        <div className="flex items-center justify-center gap-2">
                          <button
                            onClick={() =>
                              dispatch(
                                drecreaseQuantity({ codigo: item.codigo })
                              )
                            }
                            className="px-2 py-1 md:px-3 md:py-1 border rounded-lg hover:bg-gray-200 duration-200"
                          >
                            -
                          </button>
                          <span>{item.quantity}</span>
                          <button
                            onClick={() =>
                              dispatch(
                                increaseQuantity({ codigo: item.codigo })
                              )
                            }
                            className="px-2 py-1 md:px-3 md:py-1 border rounded-lg hover:bg-gray-200 duration-200"
                          >
                            +
                          </button>
                        </div>
                      </td>
                      <td className="text-right p-2">
                        {item.simbolo}
                        {formatPrice(total)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
  
          <div className="flex flex-wrap gap-4 justify-between">
            <button
              type="button"
              onClick={() => dispatch(resetCart())}
              className="py-2 px-6 bg-red-500 text-white font-semibold uppercase hover:bg-red-700 duration-300"
            >
              Vaciar carrito
            </button>
            <Link to="/">
              <button className="py-2 px-6 bg-green-500 text-white font-semibold uppercase hover:bg-green-700 duration-300">
                Seguir comprando
              </button>
            </Link>
          </div>
  
          <div className="flex flex-col md:flex-row mt-8 gap-4 items-stretch">
  {/* Área de Notas */}
  <div className="flex-1 flex flex-col">
    <label
      htmlFor="notas"
      className="block text-base md:text-lg font-semibold mb-2"
    >
      Notas:
    </label>
    <textarea
      id="notas"
      className="w-full flex-grow border border-gray-300 rounded-md p-2 resize-none focus:outline-none focus:ring-2 focus:ring-primeColor"
      placeholder="Escribe tus notas aquí..."
    ></textarea>
  </div>

  {/* Área de Totales */}
  <div className="w-full md:w-96 flex flex-col">
    <h1 className="text-lg md:text-2xl font-semibold text-right">Total</h1>
    <div className="border border-gray-400 mt-4 p-4 flex-grow">
      <p className="flex justify-between mb-2">
        Subtotal
        <span>
          {Simbolo} {formatPrice(totalAmt)}
        </span>
      </p>
      <p className="flex justify-between mb-2">
        Cargo de envío
        <span>
          {Simbolo} {formatPrice(shippingCharge)}
        </span>
      </p>
      <p className="flex justify-between font-bold">
        Total
        <span>
          {Simbolo} {formatPrice(totalAmt + shippingCharge)}
        </span>
      </p>
    </div>
    <button
      onClick={handleProcessOrder}
      className="w-full mt-4 py-2 bg-blue-500 text-white hover:bg-blue-700 duration-300"
    >
      Finalizar
    </button>
  </div>
</div>



        </div>
      ) : (
        <motion.div
          initial={{ y: 30, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.4 }}
          className="flex flex-col items-center gap-4 pb-20"
        >
          <img
            className="w-64 rounded-lg"
            src={emptyCart}
            alt="Carrito vacío"
          />
          <div className="text-center">
            <p className="mb-4">Tu carrito está vacío.</p>
            <Link to="/">
              <button className="bg-primeColor rounded-md px-6 py-2 hover:bg-black text-white">
                Volver a la tienda
              </button>
            </Link>
          </div>
        </motion.div>
      )}
    </div>
  );
  
};

export default Cart;
