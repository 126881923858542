import React, { useState, useEffect } from 'react';
import { FaShoppingCart, FaBell } from "react-icons/fa";
import { ImBin } from "react-icons/im";
import { useLocation } from 'react-router-dom';
import { useSelector } from "react-redux";
import DataTable from 'react-data-table-component';
import { formatPrice } from "../../../constants/funciones";
import { notices, loadAddfavorites } from "../../../constants/products";
import Swal from 'sweetalert2';

const ProductTable2 = ({ products, handleImageClick, handleQuantityChange, handleAddToCart }) => {
  const location = useLocation();
  const isNewsPage = location.pathname === "/News" || location.pathname === "/Offer";
  const products2 = useSelector((state) => state.orebiReducer.products);

  const [favorites, setFavorites] = useState({});
  const [quantities, setQuantities] = useState({});
  const [previousQuantities, setPreviousQuantities] = useState({});
  const [updatedProducts, setUpdatedProducts] = useState(products);

  useEffect(() => {
    const initialQuantities = products.reduce((acc, product) => {
      const matchedProduct = products2.find((p) => p.codigo === product.codigo);
      acc[product.codigo] = matchedProduct ? matchedProduct.quantity : 0;
      return acc;
    }, {});
    setQuantities(initialQuantities);
    setPreviousQuantities(initialQuantities);
    setUpdatedProducts(products);
  }, [products, products2]);

  const toggleFavorite = async (codigo) => {
    const loggedUserData = JSON.parse(localStorage.getItem("user"));
    if (!loggedUserData?.data?.user) {
      console.log("User not authenticated, redirecting to SignIn.");
      location("/signin");
      return;
    }

    const userId = loggedUserData.data.user.id;

    const result = await Swal.fire({
      title: "¿Estás seguro?",
      text: "Esta acción eliminará el producto de favoritos.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sí, eliminar",
      cancelButtonText: "Cancelar",
      customClass: {
        confirmButton: 'swal-btn swal-btn-confirm',
        cancelButton: 'swal-btn swal-btn-cancel'
      },
      buttonsStyling: false,
    });

    if (result.isConfirmed) {
      try {
        await loadAddfavorites(userId, codigo, 1);
        Swal.fire("Eliminado", "El producto fue eliminado de favoritos.", "success");
        setUpdatedProducts((prev) => prev.filter((product) => product.codigo !== codigo));
      } catch (error) {
        console.error("Error al actualizar favoritos:", error);
        Swal.fire("Error", "No se pudo actualizar favoritos.", "error");
      }
    }
  };

  const style = document.createElement('style');
  style.innerHTML = `
    .swal-btn {
      font-size: 16px;
      font-weight: bold;
      padding: 10px 20px;
      border-radius: 5px;
      color: white;
      margin: 0 10px;
    }
    .swal-btn-confirm {
      background-color: #28a745;
      border: none;
    }
    .swal-btn-cancel {
      background-color: #dc3545;
      border: none;
    }
  `;
  document.head.appendChild(style);

  const handleQuantityChangeInternal = (codigo, newQuantity) => {
    const quantity = Number(newQuantity);
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [codigo]: quantity,
    }));
    handleQuantityChange(codigo, quantity);
  };

  const handleAddToCartInternal = (product) => {
    const { codigo } = product;
    const currentQuantity = quantities[codigo] || 0;
    const previousQuantity = previousQuantities[codigo] || 0;

    if (currentQuantity === previousQuantity) {
      const updatedQuantity = currentQuantity + 1;
      setQuantities((prev) => ({ ...prev, [codigo]: updatedQuantity }));
      setPreviousQuantities((prev) => ({ ...prev, [codigo]: updatedQuantity }));
      handleAddToCart(product, updatedQuantity);
    } else {
      setPreviousQuantities((prev) => ({ ...prev, [codigo]: currentQuantity }));
      handleAddToCart(product, currentQuantity);
    }
  };

const columns = [
  {
    name: 'Foto',
    cell: row => (
      <img
        src={row.img}
        alt={row.Descripcion}
        className="object-cover cursor-pointer"
        style={{ width: '40px', height: '40px' }}
        onClick={() => handleImageClick(row)}
      />
    ),
  },
  {
    name: <div style={{ width: '100%', textAlign: 'left' }}>Código</div>,
    cell: row => (
        <div
            style={{
                cursor: 'pointer',
                width: '100%',
                textAlign: 'left',
                fontSize: '10px',
            }}
            onClick={() => handleImageClick(row)}
        >
            {row.codigo}
        </div>
    ),
    sortable: true,
    sortFunction: (a, b) => {
        // Convierte los valores a números si es posible
        const aValue = isNaN(a.codigo) ? a.codigo : parseFloat(a.codigo);
        const bValue = isNaN(b.codigo) ? b.codigo : parseFloat(b.codigo);

        // Si ambos son números, los comparamos numéricamente
        if (typeof aValue === 'number' && typeof bValue === 'number') {
            return aValue - bValue;
        }

        // Si son cadenas de texto, usamos localeCompare
        if (typeof aValue === 'string' && typeof bValue === 'string') {
            return aValue.localeCompare(bValue);
        }

        // Si son diferentes tipos (un número y una cadena), los comparamos como texto
        return String(aValue).localeCompare(String(bValue));
    },
},
{
    name: <div style={{ width: '100%' }}>Descripción</div>,
    cell: row => (
      <div style={{ display: 'flex', alignItems: 'center' }}>
      <span style={{ flex: 1 }} onClick={() => handleImageClick(row)}>
        {row.Descripcion}
      </span>
    </div>
    ),
    sortable: true,
    wrap: true,
    sortFunction: (a, b) => {
        // Intenta convertir los valores a números si es posible
        const aValue = isNaN(a.Descripcion) ? a.Descripcion : parseFloat(a.Descripcion);
        const bValue = isNaN(b.Descripcion) ? b.Descripcion : parseFloat(b.Descripcion);

        // Si ambos son números, los comparamos numéricamente
        if (typeof aValue === 'number' && typeof bValue === 'number') {
            return aValue - bValue;
        }

        // Si son cadenas de texto, usamos localeCompare
        if (typeof aValue === 'string' && typeof bValue === 'string') {
            return aValue.localeCompare(bValue);
        }

        // Si son diferentes tipos (un número y una cadena), los comparamos como texto
        return String(aValue).localeCompare(String(bValue));
    },
},
  {
    name: <div style={{ textAlign: 'right', width: '100%' }}>IVA</div>,
    selector: row => row.iva,
    cell: row => <div style={{ textAlign: 'right', width: '100%' }}>{row.iva}</div>,
  },
  {
    name: <div style={{ textAlign: 'right', width: '100%' }}>Precio</div>,
    cell: row => (
      <div style={{ textAlign: 'right', width: '100%' }}>
        <span
          className="precio-lista"
          style={{
            textDecoration: row.PrecioOferta > 0 ? 'line-through' : 'none',
            fontSize: '12px',
          }}
        >
          {row.simbolo} {formatPrice(row.PrecioLista || 0)}
        </span>
        {row.PrecioOferta > 0 && (
          <div>
            <span
              className="precio-oferta"
              style={{
                fontWeight: 'bold',
                fontSize: '12px',
                display: 'block',
                marginTop: '2px',
              }}
            >
              {row.simbolo} {formatPrice(row.PrecioOferta || 0)}
            </span>
          </div>
        )}
      </div>
    ),
    sortable: true,
    sortFunction: (rowA, rowB) => {
      const priceA = rowA.PrecioOferta > 0 ? rowA.PrecioOferta : rowA.PrecioLista || 0;
      const priceB = rowB.PrecioOferta > 0 ? rowB.PrecioOferta : rowB.PrecioLista || 0;
      return priceA - priceB;
    },
  },
  {
    name: <div style={{ textAlign: 'right', width: '100%' }}>Stock</div>,
    cell: row => (
      <div
        style={{
          textAlign: 'right',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <span>{parseInt(row.Stock, 10)}</span>
       
      </div>
    ),
    sortable: true,
  },  
  {
    name: 'Condicion',
    selector: row => row.atributo,
    sortable: true,
    wrap: true,
  },
  {
    name: 'Grupo',
    selector: row => row.grupo,
    sortable: true,
    wrap: true,
  },
  {
    name: 'Marca',
    selector: row => row.marca,
    sortable: true,
    wrap: true,
  },
  ...(isNewsPage
    ? []
    : [
        {
          name: 'Cantidad',
          cell: row => (
            <input
              type="number"
              id={`quantity-input-${row.codigo}`}
              min="0"
              value={quantities[row.codigo] || 0}
              onChange={e => handleQuantityChangeInternal(row.codigo, e.target.value)}
              className="w-10 border border-gray-300 rounded text-right"
              style={{ fontSize: '10px', textAlign: 'right' }}
            />
          ),
          ignoreRowClick: true,
        },
        {
          name: 'Agregar',
          cell: row => (
            <div style={{ display: 'flex', alignItems: 'center' }}>
            <button
              onClick={() => handleAddToCartInternal(row)}
              className="bg-blue-500 text-white px-1 py-0.5 rounded hover:bg-blue-700"
              style={{ fontSize: '10px' }}
            >
              <FaShoppingCart />
            </button>
            <ImBin
               className="ml-2 text-sm text-primeColor hover:text-red-500 duration-300 cursor-pointer"
              onClick={() => toggleFavorite(row.codigo)}
            />
          </div>          
          ),
          ignoreRowClick: true,
        },
      ]),
];


  return (
    <DataTable
    columns={columns}
    data={updatedProducts} 
    customStyles={{
      rows: {
        style: {
          minHeight: '50px',
        },
      },
      headCells: {
        style: {
          fontSize: '12px',
          fontWeight: 'bold',
          paddingLeft: '8px',
          paddingRight: '8px',
          backgroundColor: '#f1f3f5',
        },
      },
      cells: {
        style: {
          fontSize: '11px',
          paddingLeft: '8px',
          paddingRight: '8px',
          whiteSpace: 'normal',
          overflow: 'visible',

        },
      },
    }}

  />

  );
};

export default ProductTable2;
